import { articlesPostTypeSlug } from "./Global"

// Icon Boxes
export const iconBoxes = [
  {
    image: {
      path: "help-and-support--account-icon",
      alt: "help-and-support--account-icon",
    },
    title: "Account",
    link: "https://partners.synkli.com.au/",
    description: `We're here to assist you with all your account related needs`,
  },
  {
    image: {
      path: "help-and-support--integration-icon",
      alt: "help-and-support--account-icon",
    },
    title: "Integrations",
    link: "https://partners.synkli.com.au/",
    description: `Integrate Synkli with your other systems`,
  },
  {
    image: {
      path: "help-and-support--subscription-icon",
      alt: "help-and-support--account-icon",
    },
    title: "Subscription",
    link: "https://partners.synkli.com.au/",
    description: `We're here to assist you with all your subscription needs.`,
  },
]

// Content Sections
export const contentSections = [
  {
    title: "Scan and Upload Receipts",
    description:
      "Use the mobile app to scan receipts on the go. The app utilizes OCR technology to extract key details from your receipts. Use the mobile app to scan receipts on the go. The app utilizes OCR technology to extract key details from your receipts.",
    list: [
      "Open the receipt scanning feature in the mobile app.",
      "Take a clear photo of the receipt.",
      "Allow the app to extract and categorize the expense details automatically.",
    ],
    image: {
      path: "scan-and-upload",
      alt: "scan-and-upload",
    },
  },
  {
    title: "Track Mileage for Tax Deductions",
    description:
      "Use Synkli’s mileage tracking feature to log business trips. Input details like the date, purpose of the trip, and odometer readings. Enable automatic mileage tracking if your vehicle usage is frequent and consistent.",
    list: [
      "Open the Synkli app and select Trip Logbook.",
      "Enter the trip details (e.g. starting and ending odometer readings).",
      "Save the entry to automatically calculate the deductible mileage.",
    ],
    image: null, // No image for this section
  },
  {
    title: "Generate Reports and Tax Documents",
    description:
      "Generate detailed expense reports to review your spending and tax deduction. Use Synkli’s tools to prepare your tax documents, ensuring all deductions are accurately accounted for. Generate detailed expense reports to review your spending and tax deductions. Use Synkli’s tools to prepare your tax documents, ensuring all deductions are accurately accounted for. Generate detailed expense reports to review your spending and tax deductions. Use Synkli’s tools to prepare your tax documents, ensuring all deductions are accurately accounted for.",
    list: [],
    image: {
      path: "generate-reports",
      alt: "generate-reports",
    },
  },
]
