// src/components/IconBox.js

import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const IconBox = ({
  componentEC,
  IconBoxLink,
  image,
  imageEC,
  title,
  titleEC,
  description,
  descriptionEC,
}) => {
  return (
    <div
      className={`flex flex-col gap-5 bg-white shadow-md cursor-pointer border border-[#001F1F08] rounded-3xl w-full py-6 px-6 ${componentEC}`}
      onClick={() => window.location.href = IconBoxLink}
    >
      <div className={`max-w-[70px] ${imageEC}`}>
        <ImageRenderer img={image.path} alt={image.alt} />
      </div>
      {title && (
        <h2
          className={`text-[22px] md:text-[24px] leading-[1.2em] font-[600] text-[#000000] ${titleEC}`}
        >
          {title}
        </h2>
      )}

      {description && (
        <p
          className={`text-[16px] md:text-[18px] leading-[1.4em] text-[#555555] ${descriptionEC}`}
        >
          {description}
        </p>
      )}
    </div>
  )
}

export default IconBox
